<app-carousel section="company"></app-carousel>
<div id="main">
  <h1>{{'COMPANY.title'|translate}}</h1>
  <ng-template [ngIf]="contents">
    <ng-template [ngIf]="contents.length > 1">
      <h3>{{contents[0].title}}</h3>
      <markdown ngPreserveWhitespaces class="info">{{contents[0].body}}</markdown>
      <ng-template [ngIf]="contents[0].file && contents[0].image">
        <video class="video" [src]="contents[0].file" controls loop [muted]="'muted'" autoplay [poster]="contents[0].image" width="854" height="428"></video>
      </ng-template>
    </ng-template>
    <div class="statements">
      <div class="titles">
        <div class="title" *ngFor="let statement of statements; let i = index" [ngClass]="statement.active?'active':''" (click)="activateStatement(i);">
          {{statement.content.title}}
        </div>
      </div>
      <div class="bodies">
        <markdown ngPreserveWhitespaces  class="body" *ngFor="let statement of statements" [ngClass]="statement.active?'active':''">
          {{statement.content.body}}
        </markdown>
      </div>
    </div>
  </ng-template>
  <div class="brochure" *ngIf="brochure">
    <h3>{{brochure.title}}</h3>
    <a [href]="brochure.file" target="_blank" class="download">
      <span>{{'COMPANY.download_brochure'|translate}}</span>
      <img [src]="brochure.image" alt="">
    </a>
  </div>
  <div class="contact">
    <h4>{{'COMPANY.contact'|translate}}</h4>
    <div class="icons">
      <a href="mailto:info@sirenta.com" class="icon">
        <img src="/assets/img/icons/email.png" alt="">
        <span>info@sirenta.com</span>
      </a>
      <a href="tel:5529215759" class="icon">
        <img src="/assets/img/icons/fax.png" alt="">
        <span>55 2921 5759</span>
      </a>
      <a href="https://api.whatsapp.com/send?phone=+525529215759" target="_blank" class="icon">
        <img src="/assets/img/icons/phone.png" alt="">
        <span>55 2921 5759</span>
      </a>
    </div>
  </div>
  <app-appointment></app-appointment>
</div>
