import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { CompanyComponent } from './pages/company/company.component';
import { PromosComponent } from './pages/promos/promos.component';
import { SirentaFlexComponent } from './pages/sirenta-flex/sirenta-flex.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ToursComponent } from './pages/tours/tours.component';
import { AppointmentComponent_ } from './pages/appointment/appointment.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'development/:slug', component: DevelopmentComponent },
  { path: 'company', component: CompanyComponent },
  { path: 'promos', component: PromosComponent },
  { path: 'sirenta-flex', component: SirentaFlexComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'tours', component: ToursComponent },
  { path: 'appointment', component: AppointmentComponent_ },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
