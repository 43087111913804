import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';
import { Development } from '../../models/developments';
import { Content } from "../../models/cms";

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})
export class DevelopmentComponent implements OnInit {
  slug: string;
  development: Development;
  bosquereal_content: Content;

  constructor(
    private api:ApiService,
    private language:LanguageService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_ => {
      this.development = undefined;
      this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
      this.api.getDevelopment(this.slug).subscribe(data => {this.development = data;});
      this.api.getContents('bosque-real').subscribe(data => {this.bosquereal_content = data[0]});
    });
  }

  newTab(url:string){
    window.open(url,'_blank');
  }
}
