import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient,HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { AgmCoreModule } from '@agm/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { MarkdownModule } from 'ngx-markdown';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { SafePipe } from "./pipes/safe";
import { MatterportEmbeddablePipe } from "./pipes/matterport_embeddable";

import { HomeComponent } from './pages/home/home.component';
import { NavComponent } from './components/nav/nav.component';
import { HeaderComponent } from './components/header/header.component';
import { AvailabilityComponent } from './components/availability/availability.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ReelComponent } from './pages/home/components/reel/reel.component';
import { DevelopmentsComponent } from './components/developments/developments.component';
import { FooterComponent } from './components/footer/footer.component';
import { BosquerealComponent } from './components/bosquereal/bosquereal.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { DevelopmentGalleryComponent } from './components/development-gallery/development-gallery.component';
import { AmenitiesComponent } from './components/amenities/amenities.component';
import { VirtualToursComponent } from './components/virtual-tours/virtual-tours.component';
import { PromosComponent } from './pages/promos/promos.component';
import { CompanyComponent } from './pages/company/company.component';
import { SirentaFlexComponent } from './pages/sirenta-flex/sirenta-flex.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ToursComponent } from './pages/tours/tours.component';
import { AppointmentComponent_ } from './pages/appointment/appointment.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';


export function createTranslateLoader(http:HttpClient) {
  return new TranslateHttpLoader(http,'assets/i18n/','.json');
}

@NgModule({
  declarations: [
    SafePipe,
    MatterportEmbeddablePipe,
    AppComponent,
    HomeComponent,
    NavComponent,
    HeaderComponent,
    AvailabilityComponent,
    CarouselComponent,
    ReelComponent,
    DevelopmentsComponent,
    FooterComponent,
    BosquerealComponent,
    BenefitsComponent,
    DevelopmentComponent,
    DevelopmentGalleryComponent,
    AmenitiesComponent,
    VirtualToursComponent,
    PromosComponent,
    CompanyComponent,
    SirentaFlexComponent,
    ContactComponent,
    ToursComponent,
    AppointmentComponent_,
    AppointmentComponent,
    FloatingMenuComponent,
    PopUpComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InlineSVGModule,
    ReactiveFormsModule,
    GoogleTagManagerModule.forRoot({
      id:'GTM-W2R97QP',
    }),
    MarkdownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC8Fg2Hh44gTeMcWNX0MVlcew6NKeR6Smc',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-W2R97QP'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
