<div class="section">
  <h2>{{"DEVELOPMENT.amenities.title"|translate}}</h2>
  <div class="amenities">
    <div class="titles">
      <div
        class="title"
        [ngClass]="active_title==amenity.slug?'active':''"
        *ngFor="let amenity of amenities"
        (click)="titleSelect(amenity.slug)">
        {{amenity.name}}
      </div>
    </div>
    <div class="amenity">
      <ng-template ngFor let-amenity [ngForOf]="amenities">
        <div
          class="image"
          [ngClass]="[
            active_image==image.slug?'active':''
          ]"
          *ngFor="let image of amenity.images">
          <img [src]="image.image" [alt]="amenity.name">
        </div>
      </ng-template>
      <div class="prev" (click)="prevImage()">
        <span><mat-icon>chevron_left</mat-icon></span>
      </div>
      <div class="next" (click)="nextImage()">
        <span><mat-icon>chevron_right</mat-icon></span>
      </div>
    </div>
  </div>
</div>
