<div class="appointments">
  <h2>{{'APPOINTMENT.title'|translate}}</h2>
  <div class="tours">
    <div class="tour">
      <div class="description">
        {{'APPOINTMENT.virtual_tour'|translate}}
      </div>
      <a href="https://sirenta.com/recorridos/virtual/index.html" target="_blank" class="appointment">{{'APPOINTMENT.make_your_appointment'|translate}}</a>
    </div>
    <div class="tour">
      <div class="description">
        {{'APPOINTMENT.in_person_tour'|translate}}
      </div>
      <a href="https://sirenta.com/recorridos/presencial/index.html" target="_blank" class="appointment">{{'APPOINTMENT.make_your_appointment'|translate}}</a>
    </div>
  </div>
</div>
<div class="map">
  <div class="wrapper">
    <div class="google-maps">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [opacity]="marker.alpha"
              ></agm-marker>
      </agm-map>
    </div>
  </div>
</div>
