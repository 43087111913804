import { Component, OnInit, Input } from '@angular/core';
import { Amenity } from "../../models/developments";

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit {
  @Input() amenities: Amenity[];
  active_image: string;
  active_title: string;

  constructor() { }

  ngOnInit(): void {
    this.active_image = this.amenities[0].images[0].slug;
    this.active_title = this.amenities[0].slug;
  }

  nextImage() {
    let n = false;
    let last_image = this.active_image;
    amenities:
    for(let amenity of this.amenities){
      amenity:
      for(let image of amenity.images){
        if(n){
          this.active_image = image.slug;
          n = false;
          this.active_title = amenity.slug;
          break amenities;
        }
        else if(this.active_image == image.slug){
          n = true;
        }
      }
    }
    if(n){
      this.active_image = this.amenities[0].images[0].slug;
      this.active_title = this.amenities[0].slug;
    }
  }

  prevImage() {
    let n = false;
    let last_image = '';
    let last_title = '';
    amenities:
    for(let amenity of this.amenities){
      amenity:
      for(let image of amenity.images){
        if(this.active_image == image.slug){
          break amenities;
        }
        last_image = image.slug;
        last_title = amenity.slug;
      }
    }
    if(last_image){
      this.active_image = last_image;
      this.active_title = last_title;
    }else{
      let amenity_ = this.amenities[this.amenities.length-1];
      this.active_image = amenity_.images[amenity_.images.length-1].slug;
      this.active_title = amenity_.slug;
    }
  }

  titleSelect(slug) {
    this.active_title = slug;
    for(let amenity of this.amenities){
      if(amenity.slug == slug){
        this.active_image = amenity.images[0].slug;
        break;
      }
    }
  }
}
