<div class="contact">
  <div class="social">
    <div class="email">
      <a href="mailto:{{email}}">{{email}}</a>
    </div>
    <div class="social-networks">
      <ng-template ngFor let-social_network [ngForOf]="social_networks">
        <div *ngIf="!social_network.appointment" class="social-network">
          <a href="{{social_network.url}}" target="_blank"><img src="{{social_network.image}}" alt="{{social_network.name}}"></a>
        </div>
      </ng-template>
    </div>
    <div class="phone">
      <a href="tel:{{phone}}"><mat-icon>phone</mat-icon> {{phone}}</a>
    </div>
  </div>
  <div class="appointment">
    <div>{{"FOOTER.make_appointment"|translate}}</div>
    <a routerLink="appointment">
      <img src="/assets/img/icons/calendar.png" alt="">
    </a>
    <ng-template ngFor let-social_network [ngForOf]="social_networks">
      <a href="{{social_network.url}}" *ngIf="social_network.appointment" target="_blank">
        <img src="{{social_network.image}}" alt="{{social_network.name}}">
      </a>
    </ng-template>
  </div>
</div>
<div class="main">
  <div class="slogan">
    <div class="wrapper">
      {{slogan}}
    </div>
  </div>
  <div class="copyright" *ngIf="copyright && copyright_file">
    <a [href]="copyright_file" target="_blank" class="wrapper">
      <markdown>{{copyright}}</markdown>
    </a>
  </div>
  <div class="logo">
    <div class="wrapper">
      <img [src]="logo" alt="logo">
    </div>
  </div>
</div>
