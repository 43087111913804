import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent implements OnInit {
  @HostBinding('class.active') active: boolean = false;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.active = true;
    }, 4000);
  }

  closeMenu() {
    this.active = false;
  }
}
