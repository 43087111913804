import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LanguageService } from "../../services/language.service";
import { Content } from "../../models/cms";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  contents: Content[];
  statements: any[];
  brochure: Content;

  constructor(
    private api: ApiService,
    private language: LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_ => {
      this.api.getContents('company').subscribe(data => {
        this.contents = data;
        this.statements = this.contents.slice(1).map(c=>{return {content:c,active:false}});
        if(this.statements.length)this.statements[0].active=true;
      });
      this.api.getContents('company-brochure').subscribe(data => {
        this.brochure = data[0];
      });
    });
  }

  activateStatement(index:number){
    for(let i=0;i<this.statements.length;i++){
      this.statements[i].active = false;
    }
    this.statements[index].active = true;
  }
}
