import { Component, OnInit } from '@angular/core';
import { Development } from "../../models/developments";
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {
  development: Development;

  constructor(
    private api:ApiService,
  ) { }

  ngOnInit(): void {
  }

}
