<div class="section virtual-tour" *ngIf="development.amenities_virtual_tour_link || virtual_tours.length">
  <h3>{{"VIRTUAL_TOURS.amenities.title"|translate:development}}</h3>
  <div class="description" *ngIf="false">
    {{"VIRTUAL_TOURS.amenities.description"|translate:development}}
  </div>
  <ng-template [ngIf]="(development.amenities_virtual_tour_link|is_matterport_embeddable)">
    <iframe width="853" height="480" [src]="development.amenities_virtual_tour_link|safe" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
  </ng-template>
  <ng-template [ngIf]="development.amenities_virtual_tour_link && development.amenities_virtual_tour_image">
    <a [href]="development.amenities_virtual_tour_link" target="_blank" [ngClass]="(development.amenities_virtual_tour_link|is_matterport_embeddable)?'sm':''">
      <img [src]="development.amenities_virtual_tour_image" alt="">
    </a>
  </ng-template>
  <div class="description">
    <markdown ngPreserveWhitespaces>{{development.description}}</markdown>
  </div>
  <div class="sample-apartment" *ngIf="development.virtual_tour_link && virtual_tours.length">
    <h2>{{"VIRTUAL_TOURS.sample_apartment.title"|translate}}</h2>
    <ng-template [ngIf]="development.virtual_tour_link|is_matterport_embeddable">
      <iframe width="853" height="480" [src]="development.virtual_tour_link|safe" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
    </ng-template>
    <ng-template [ngIf]="development.virtual_tour_link && development.virtual_tour_image">
      <a [href]="development.virtual_tour_link" target="_blank" [ngClass]="(development.virtual_tour_link|is_matterport_embeddable)?'sm':''">
        <img [src]="development.virtual_tour_image" alt="">
      </a>
    </ng-template>
    <div class="description">
      <h4>{{"VIRTUAL_TOURS.sample_apartment.with_rooms"|translate:virtual_tours[0]}}</h4>
      <span>{{"VIRTUAL_TOURS.sample_apartment.description"|translate}}</span>
    </div>
  </div>
</div>
<div>
  <h3>{{"VIRTUAL_TOURS.know_your_options"|translate}}</h3>
  <app-development-gallery [development]="development" *ngIf="development.images.length"></app-development-gallery>
  <div class="apartment-options" *ngIf="false">
    <ng-template ngFor let-unit_type [ngForOf]="development.unit_types">
      <a [href]="unit_type.virtual_tour_link" target="_blank" class="apartment" *ngIf="unit_type.virtual_tour_link && unit_type.image">
        <div class="image">
          <img [src]="unit_type.image" alt="">
        </div>
        <div class="info">
          {{"DEVELOPMENT.virtual_tour.apartments_of"|translate}} <br>
          <b>
            <span [ngPlural]="unit_type.bed_rooms">
              <ng-template ngPluralCase="=1">{{"DEVELOPMENT.our_apartments.room.singular"|translate:unit_type}}</ng-template>
              <ng-template ngPluralCase="other">{{"DEVELOPMENT.our_apartments.room.plural"|translate:unit_type}}</ng-template>
            </span>
            <span *ngIf="unit_type.family_room"> + {{"DEVELOPMENT.our_apartments.family_room"|translate}}</span>
          </b>
          <br>
          <span>{{unit_type.area}}M<sup>2</sup></span>
        </div>
      </a>
    </ng-template>
  </div>
  <div class="apartment-360s" *ngIf="false">
    <ng-template ngFor let-unit_type [ngForOf]="development.unit_types">
      <div class="apartment-360" *ngIf="unit_type._360_image && unit_type._360_link">
        <a [href]="unit_type._360_link" target="_blank">
          <img [src]="unit_type._360_image" alt="">
          <span>
            {{"DEVELOPMENT.virtual_tour.360_tour_new_home"|translate}}<br>
            <b>{{"DEVELOPMENT.virtual_tour.360_tour"|translate}}</b>
          </span>
        </a>
      </div>
    </ng-template>
  </div>
  <app-amenities *ngIf="development.amenities.length" [amenities]="development.amenities"></app-amenities>
  <div class="amenities">
    <div class="amenities-360">
      <ng-template ngFor let-amenity [ngForOf]="development.amenities">
        <div class="amenity-360" *ngIf="amenity._360_link && amenity._360_image">
          <a [href]="amenity._360_link" target="_blank">
            <img [src]="amenity._360_image" alt="">
            <span>{{amenity.name}}</span>
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
