import { Component, Input, ViewChild, AfterViewInit,isDevMode } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { MatSidenav,MatSidenavContainer } from '@angular/material/sidenav';
import { LanguageService } from "./services/language.service";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from "rxjs/operators";

declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  @ViewChild('mainDrawer') mainDrawer: MatSidenav;
  @ViewChild('sidenavContainer') sidenavContainer: MatSidenavContainer;
  title = 'sirenta';

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private meta:Meta,
  ) {
    this.initializeApp();
    if(!isDevMode()){
      this.initGoogleAnalytics();
    }
  }

  initMetaTags() {

  }

  initializeApp () {
    this.languageService.setInitialAppLanguage();
    this.meta.addTags([
      { name: 'keywords', content: 'five bosque real renta, renta en bosque real, departamentos en renta en bosque real , renta de departamentos en bosque real, renta de departamentos en cdmx, bosque real, bosque real five, la vista, the oaks, desarrollos en bosque real, bosque real la vista, sirenta, bienes raíces mexico, departamentos en bosque real, renta de departamentos , departamentos en renta , donde vivir en ciudad de mexico , mejor lugar para vivir en la cdmx, vive en bosque real, bosque real renta, departamentos amueblados , departamentos amueblados en bosque real, recorrido virtual bosque real, Renta en el poniente de la CDMX' },
      { name: 'author', content: 'Behuns' },
      { name: 'robots', content: 'index, follow' },
      { name: 'description', content: 'SìRENTA ofrece proyectos residenciales en renta en Bosque Real, con acabados de alta calidad y con precios competitivos. Nosotros te ayudamos a encontrar el departamento de tus sue?os en bosque real.' },
    ]);
  }

  initGoogleAnalytics() {
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-160710350-1', {
        'page_path': event.urlAfterRedirects
      });
      fbq('track', 'PageView');
      const gtmTag = {
          event: 'page',
          pageName: event.url
      };
      console.log(gtmTag);
      this.gtmService.pushTag(gtmTag);
    });
  }

  toggleMainDrawer () {
    this.mainDrawer.toggle();
  }

  ngAfterViewInit() {
    this.sidenavContainer.scrollable.elementScrolled().subscribe(() => {
      console.log('scroll');
    });
  }
}
