<app-carousel section="promos"></app-carousel>

<div id="main">
  <ng-template [ngIf]="content">
    <h1>{{content.title}}</h1>
    <markdown ngPreserveWhitespaces class="info">{{content.body}}</markdown>
  </ng-template>
  <div #zone_data class="zones" id="zones">
    <div class="zone" *ngFor="let zone of zones">
      <div class="image">
        <img [src]="zone.image" alt="">
      </div>
      <div class="label" [style.background-color]="zone.color">
        {{"PROMOS.zone"|translate:zone}}
      </div>
      <div class="description">
        {{zone.description}}
      </div>
      <div class="value">
        <span>{{"PROMOS.benefits_value"|translate}}</span>
        <div [style.color]="zone.color" class="amount">
          {{"PROMOS.benefits_up_to"|translate}} <span>${{zone.value.toLocaleString('en',{maximumFractionDigits: 0,minimumFractionDigits: 0})}}**</span> {{"PROMOS.benefits_monthly"|translate}}</div>
        <div class="disclaimer">
          **{{"PROMOS.benefits_disclaimer"|translate:zone}}
        </div>
      </div>
      <div class="benefits">
        <div class="benefit" *ngFor="let benefit of zone.benefits; index as i">
          <img [src]="benefit.image" alt="">
          <span>{{benefit.name}}</span>
        </div>
        <div class="restrictions">
          *{{"PROMOS.restrictions_apply"|translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="promo" *ngIf="promo">
    <h2>{{"PROMOS.monthly_campaign.title"|translate}}</h2>
    <div class="description">
      {{"PROMOS.monthly_campaign.description"|translate}}
    </div>
    <img src="{{promo.image}}" alt="">
  </div>
</div>
