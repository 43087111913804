import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  latitude: number = 19.420216;
  longitude: number = -99.3002272;
  zoom: number = 15;
  marker = {
    lat: 19.420216,
    lng: -99.2980385,
    alpha: 1,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
