<ng-template [ngIf]="development">
  <app-carousel [section]="development.slug"></app-carousel>
  <h1>{{development.name}}</h1>
  <div class="description">
    <markdown ngPreserveWhitespaces>{{development.description}}</markdown>
  </div>
  <div class="section our-apartments" *ngIf="development.unit_types.length">
    <h2>{{"DEVELOPMENT.our_apartments.title"|translate}}</h2>
    <div class="unit-types">
      <div class="unit-type" *ngFor="let unit_type of development.unit_types">
        <h3>
        <span [ngPlural]="unit_type.bed_rooms">
          <ng-template ngPluralCase="=1">{{"DEVELOPMENT.our_apartments.room.singular"|translate:unit_type}}</ng-template>
          <ng-template ngPluralCase="other">{{"DEVELOPMENT.our_apartments.room.plural"|translate:unit_type}}</ng-template>
        </span>
        <span *ngIf="unit_type.family_room"> + {{"DEVELOPMENT.our_apartments.family_room"|translate}}</span></h3>
        <div class="image" *ngIf="!unit_type.virtual_tour_link">
          <img [src]="unit_type.layout" alt="">
        </div>
        <a class="image" *ngIf="unit_type.virtual_tour_link" [href]="unit_type.virtual_tour_link" target="_blank">
          <div class="overlay">
            <img src="/assets/img/icons/360-0.svg" alt="">
            <span>{{"APPOINTMENT.virtual_tour"|translate}}</span>
          </div>
          <img [src]="unit_type.layout" alt="">
        </a>
        <button mat-flat-button color="primary" (click)="newTab(unit_type.datasheet)">{{"DEVELOPMENT.our_apartments.datasheet"|translate}}</button>
      </div>
    </div>
  </div>
  <app-availability [development]="development" *ngIf="development.towers.length"></app-availability>
  <app-virtual-tours [development]="development"></app-virtual-tours>
  <div class="section bosque-real" *ngIf="bosquereal_content">
    <h2>{{bosquereal_content.title}}</h2>
    <div class="content">
      <markdown ngPreserveWhitespaces>{{bosquereal_content.body}}</markdown>
      <video class="video" [src]="bosquereal_content.file" controls loop [muted]="'muted'" autoplay [poster]="bosquereal_content.image" width="854" height="428"></video>
    </div>
  </div>
</ng-template>
