import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LanguageService } from "../../services/language.service";
import { ApiService } from "../../services/api.service";
import { Development } from "../../models/developments";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  developments: Development[];

  constructor(
    private router: Router,
    private language: LanguageService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.api.getDevelopments().subscribe(data => {
      this.developments = data;
    });
  }

  setLanguage(lang:string) {
    this.language.setLanguage(lang);
  }

  getLanguage() {
    return this.language.getLanguage();
  }
}
