import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

const header_height = 64;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMainDrawer = new EventEmitter();
  fixed_header: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll() {
     this.fixed_header = header_height < document.documentElement.scrollTop;
  }

  toggleMainDrawer_ () {
    this.toggleMainDrawer.emit();
  }

}
