import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LanguageService } from "../../services/language.service";
import { Content,SocialNetwork } from "../../models/cms";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  slogan: string;
  copyright: string;
  copyright_file: string;
  logo: string;
  phone: string;
  email: string;
  social_networks: SocialNetwork[];

  constructor(
    private api: ApiService,
    private language: LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_=>{
      this.api.getContents('footer-slogan').subscribe(data => {this.slogan = data[0].body});
      this.api.getContents('footer-copyright').subscribe(data => {
        this.copyright = data[0].body;
        this.copyright_file = data[0].file;
      });
      this.api.getContents('footer-logo').subscribe(data => {this.logo = data[0].image});
      this.api.getContents('contact-phone').subscribe(data => {this.phone = data[0].body});
      this.api.getContents('contact-email').subscribe(data => {this.email = data[0].body});
      this.api.getSocialNetworks().subscribe(data => {this.social_networks = data});
    });
  }
}
