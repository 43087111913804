import { Component, OnInit } from '@angular/core';
import { Contact } from "../../models/contacts";
import { FormControl,Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ContactComponent implements OnInit {
  contact: Contact;
  contact_types: any;
  contact_type: string;
  posting: boolean = false;
  posted: boolean = false;
  // Form inputs
  email = new FormControl('', [Validators.required, Validators.email]);
  name = new FormControl('', [Validators.required]);
  last_name = new FormControl('', [Validators.required]);
  phone = new FormControl('', [Validators.required]);
  company_name = new FormControl('', [Validators.required]);
  company_position = new FormControl('', [Validators.required]);

  constructor(
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.contact_type = 'individual';
    this.contact_types = {
      broker:{
        fields: ['name','last_name','email','phone','company_name'],
        icon: '/assets/img/icons/broker.png',
        name: 'CONTACT.broker',
        slogan: 'CONTACT.broker_slogan',
        summary: 'CONTACT.broker_summary',
      },
      company:{
        fields: ['name','last_name','email','phone','company_name','company_position'],
        icon: '/assets/img/icons/company.png',
        name: 'CONTACT.company',
        slogan: 'CONTACT.company_slogan',
        summary: 'CONTACT.company_summary',
      },
      individual:{
        fields: ['name','last_name','email','phone'],
        icon: '/assets/img/icons/client.png',
        name: 'CONTACT.client',
        slogan: 'CONTACT.client_slogan',
        summary: 'CONTACT.client_summary',
      },
    };
  }

  selectContactType(contact_type){
    this.contact_type = contact_type;
    console.log(contact_type);
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Este campo es requerido';
    }
    return this.email.hasError('email') ? 'Correo inválido' : '';
  }

  sendForm() {
    let invalid = false;
    this.name.markAsTouched({ onlySelf: true });
    this.company_name.markAsTouched({ onlySelf: true });
    this.email.markAsTouched({ onlySelf: true });
    this.company_position.markAsTouched({ onlySelf: true });
    this.phone.markAsTouched({ onlySelf: true });
    if(
      this.name.status == 'INVALID'||
      this.email.status == 'INVALID'||
      this.phone.status == 'INVALID'){
      invalid = true;
    }
    if(this.contact_type == 'company'){
      if(
        this.company_name.status == 'INVALID'||
        this.company_position.status == 'INVALID'){
        invalid = true;
      }
    }
    if(this.contact_type == 'broker'){
      if(this.company_name.status == 'INVALID'){
        invalid = true;
      }
    }
    if(!invalid){
      this.posting = true;
      this.contact = {
        name: this.name.value,
        last_name: this.last_name.value,
        email: this.email.value,
        phone: this.phone.value,
        company_name: this.company_name.value,
        company_position: this.company_position.value,
        type: this.contact_type,
      }
      this.api.createContact(this.contact).subscribe(data => {
        console.log(data);
        this.posted = true;
      });
    }
  }
}
