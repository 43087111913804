<ng-template [ngIf]="carousel_images">
  <div class="carousel" *ngIf="carousel_images.length">
    <div class="canvas" (click)="goToUrl(url);" [ngClass]="url?'link':''">
      <div class="image" *ngFor="let carousel_image of carousel_images" [ngClass]="carousel_image.active?'active':''">
        <img src="{{carousel_image.image}}" alt="{{carousel_image.description}}">
      </div>
    </div>
    <ng-template [ngIf]="carousel_images.length > 1">
    <div class="buttons">
      <div
        class="button"
        *ngFor="let carousel_image of carousel_images; let i = index;"
        [ngClass]="carousel_images[i].active?'active':''"
        (click)="goToImage(i);"
      ></div>
    </div>
    </ng-template>
  </div>
</ng-template>
