import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

import { CarouselImage } from "../../models/cms";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  carousel_images: CarouselImage[] = [];
  slide_time: number = 5;
  url: string = '';
  @Input() section: string;
  cycle;

  constructor(
    private api:ApiService,
    private language:LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_ => {
      this.api.getCarouselImages(this.section).subscribe(data => {
        if(data.length){
          this.carousel_images = data;
          this.carousel_images[0].active=true;
          if(this.cycle){
            clearTimeout(this.cycle);
          }
          this.cycleImages();
        }
      });
    });
  }

  activateImage(index) {
    this.carousel_images[index].active = true;
    this.url = this.carousel_images[index].url;
  }

  cycleImages(): void {
    this.cycle = setTimeout(_=>{
      let i = 0
      for(;i<this.carousel_images.length;i++){
        if(this.carousel_images[i].active){
          this.carousel_images[i].active = false;
          break;
        }
      }
      if(i==this.carousel_images.length-1){
        i = 0;
      }else{
        i++;
      }
      this.activateImage(i);
      this.cycleImages();
    },this.slide_time * 1000);
  }

  goToUrl(url): void {
    if(url)
      window.location = url;
  }

  goToImage(index): void{
    for(let i = 0;i<this.carousel_images.length;i++)
      this.carousel_images[i].active = false;
    this.activateImage(index);
    clearTimeout(this.cycle);
    this.cycleImages();
  }
}
