import { Component, OnInit, Input } from '@angular/core';
import { Development,UnitType } from "../../models/developments";

@Component({
  selector: 'app-virtual-tours',
  templateUrl: './virtual-tours.component.html',
  styleUrls: ['./virtual-tours.component.scss']
})
export class VirtualToursComponent implements OnInit {
  @Input() development: Development;
  virtual_tours: UnitType[] = [];

  constructor(
  ) { }

  ngOnInit(): void {
    this.loadVirtualTours();
  }

  loadVirtualTours() {
    for(let unit_type of this.development.unit_types){
      if(unit_type.virtual_tour_link && unit_type.virtual_tour_image)
        this.virtual_tours[this.virtual_tours.length] = unit_type;
    }
    this.virtual_tours.reverse();
  }
}
