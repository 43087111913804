import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
import { Development } from '../../models/developments';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  developments: Development[];

  constructor(
    private api: ApiService,
    private meta: Meta,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.seoInit();
    this.api.getDevelopments().subscribe(data => {this.developments = data});
  }

  seoInit(): void{
    this.meta.addTags([
      {name: 'description', content: 'Departamentos en Bosque Real'},
      {name: 'author', content: 'SìRENTA'},
      {name: 'keywords', content: 'Bosque Real, Departamentos, Huixquilucan, Interlomas, Renta'}
    ]);
    this.title.setTitle('SìRENTA');
  }

}
