<div class="benefits section">
  <h2>{{'BENEFITS.title'|translate}}</h2>
  <div class="background" *ngIf="content">
    <img [src]="content.image" [alt]="content.title">
  </div>
  <div class="contents" *ngIf="content">
    <div class="content" *ngIf="content">
      {{content.body}}
    </div>
  </div>
  <div class="button" *ngIf="content">
    <a routerLink="promos" mat-flat-button color="primary">{{content.title}}</a>
  </div>
</div>
