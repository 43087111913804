import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';
import { PopUp } from "../../models/promos";

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {
  popup: PopUp;
  active: boolean = false;
  @ViewChild('image_h') image_h!: ElementRef;
  @ViewChild('image_v') image_v!: ElementRef;

  constructor(
    private api: ApiService,
    private language: LanguageService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(this.image_h){
        if(!this.image_h.nativeElement.contains(e.target)) {
          this.active=false;
        }
      }
      if(this.image_v){
        if(!this.image_v.nativeElement.contains(e.target)) {
          this.active=false;
        }
      }
    });
    this.language.language.subscribe(_ => {
      this.api.getPopUps().subscribe(data => {
        if(data.length){
          this.popup = data[0];
        }
      });
    });
  }

  loadPopUp(status:boolean): void {
    this.active = status;
  }

  loadUrl(): void {
    if(this.popup.url){
       window.open(this.popup.url, "_blank");
    }
    this.active = false;
  }
}
