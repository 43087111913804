import { Component, OnInit, Input } from '@angular/core';
import { ViewChild,ElementRef } from "@angular/core";
import { ApiService } from '../../services/api.service';
import { Development,Tower,Unit,UnitType,Zone } from "../../models/developments";
import { InlineSVGModule } from 'ng-inline-svg';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  @Input() developments: Development[];
  @Input() development: Development;
  @ViewChild('unit_data') unit_data: ElementRef;
  tower: Tower;
  units: Unit[] = [];
  unit: Unit;
  unit_type: UnitType;
  bed_rooms: number[] = [];
  prices: number[] = [];
  bed_room: number;
  price: number;
  family_room: boolean;
  status: string;
  zone: Zone;
  blocked: boolean = false;
  promo: boolean = false;

  constructor(
    private api:ApiService,
  ) { }

  ngOnInit(): void {
    this.tower = this.development.towers[0];
  }

  selectTower(): void {
    this.tower = this.tower;
    this.price = undefined;
    this.bed_room = undefined;
    this.status = '';
    this.unit = undefined;
    this.unit_type = undefined;
    this.zone = undefined;
    this.family_room = undefined;
    this.blocked = false;
    this.promo = false;
    this.applyFilters(true);
  }

  applyFilters(update_tower:boolean=false,update_prices:boolean=true): void {
    let bed_rooms:number = (this.bed_room)?Math.floor(this.bed_room):-1;
    let status:string = (this.status)?this.status:'';
    if(update_tower){
      this.api.getUnits(this.tower.slug,status,bed_rooms,this.family_room).subscribe(data => {
        this.units = data;
        this.updateUnitPrices();
        this.updateUnitRooms();
        this.updateUnitStatus();
      });
    }else{
      this.api.getUnits(this.tower.slug,status,bed_rooms,this.family_room).subscribe(data => {
        this.units = data;
        this.updateUnitStatus();
        if(update_prices)
          this.updateUnitPrices();

        // Clear unit after applying filter if not in units.
        if(this.unit){
          let clear_unit = true;
          for(let unit of this.units){
            if(unit.slug == this.unit.slug){
              clear_unit = false;
              break;
            }
          }
          if(clear_unit){
            this.unit = undefined;
            this.unit_type = undefined;
            this.zone = undefined;
            this.blocked = false;
            this.promo = false;
          }
        }
      });
    }
  }

  updateUnitRooms() {
    this.bed_rooms = [];
    for(let unit_type of this.development.unit_types){
      if(this.bed_rooms.length){
        if(this.bed_rooms.indexOf(unit_type.bed_rooms) == -1){
          this.bed_rooms[this.bed_rooms.length] = unit_type.bed_rooms;
        }
      }else{
        this.bed_rooms[0] = unit_type.bed_rooms;
      }
    }
    this.bed_rooms.sort();
  }

  updateUnitPrices() {
    let prices = [];
    this.prices = [];
    for(let [index,unit] of this.units.entries()){
      prices[index] = unit.price;
    }
    prices.sort().reverse();
    // Remove Duplicates
    prices.forEach((price) => {
      if (!this.prices.includes(price)) {
        this.prices.push(price);
      }
    });
    this.prices.reverse();
  }
  bedroomChange() {
    this.price=undefined;
    if(this.bed_room){
      if(this.bed_room%1){
        this.family_room = true;
      }else{
        this.family_room = false;
      }
    }else{
      this.family_room = undefined;
    }
    this.applyFilters();
  }

  getZone(slug:string) {
    this.api.getZone(slug).subscribe(data => {
      this.zone = data;
    });
  }

  displayUnit(e,available:boolean=true) {
    let svg_id = e.target.id;
    this.blocked = !available;
    if(this.tower) {
      for(let unit of this.units){
        if(unit.svg_id == svg_id) {
          this.unit = unit;
          this.promo = unit.status == 'promo';
          if(this.zone){
            if(this.zone.slug != this.unit.zone){
              this.zone = undefined;
            }
          }
          if(!this.zone) this.getZone(this.unit.zone);
          this.unit_type = undefined;
          for(let unit_type of this.development.unit_types){
            if(unit_type.slug == unit.type){
              this.unit_type = unit_type;
              break;
            }
          }
          break;
        }
      }
      document.getElementById('unit-data').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }


  clearUnits() {
    let deps = Array.from(document.querySelectorAll('.dep'));
    for (let d of deps) {
      d.classList.remove('available');
      d.classList.remove('taken');
      d.classList.remove('blocked');
      d.classList.remove('promo');
      d.classList.remove('emerald');
      d.classList.remove('platinum');
      d.classList.remove('gold');
    }
  }

  updateUnitStatus() {
    this.clearUnits();
    for(let unit of this.units){
      if(unit.svg_id){
        let dep = document.getElementById(unit.svg_id);
        if(dep){
          if(!this.price || unit.price == this.price){
            dep.classList.add(unit.status);
            dep.classList.add(unit.zone);
            console.log(dep.getAttribute('listener'));
            if(!dep.classList.contains('clickable')){
              if(['available','promo'].indexOf(unit.status) >= 0){
                dep.classList.add('clickable');
                dep.addEventListener("click", e => {this.displayUnit(e,true)}, true);
              }else if(unit.status == 'blocked'){
                dep.classList.add('clickable');
                dep.addEventListener("click", e => {this.displayUnit(e,false)}, true);
              }
            }
          }
        }
      }
    }
  }

  range(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => i+1)
  }
}
