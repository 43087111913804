import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'is_matterport_embeddable'
})
export class MatterportEmbeddablePipe implements PipeTransform {

  constructor(
  ) { }

  transform(url) {
    return url.indexOf('https://my.matterport.com/show/') != -1;
  }
}
