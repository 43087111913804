import { Injectable,isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CarouselImage,Content,SocialNetwork } from '../models/cms';
import { Development,Unit,Zone,Furniture } from '../models/developments';
import { Contact } from '../models/contacts';
import { Promo,PopUp } from '../models/promos';
import { LanguageService } from "./language.service";


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL: string;
  serverURL: string;

  constructor(
    private http:HttpClient,
    private language:LanguageService,
  ) {
    this.serverURL = isDevMode()?'https://sirenta.behuns.com':'https://sirenta.com';
    //this.serverURL = 'https://eileen.behuns.com';
    this.apiURL = this.serverURL+'/api/';
  }


  getHttpOptions(){
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.language.getLanguage(),
      }),
    };
  }


  getCarouselImages(section:string):Observable<CarouselImage[]> {
    return this.http.get<CarouselImage[]>(`${this.apiURL}cms/carousel-images/${section}/`,this.getHttpOptions());
  }
  getContents(section:string):Observable<Content[]> {
    return this.http.get<Content[]>(`${this.apiURL}cms/contents/${section}/`,this.getHttpOptions());
  }
  getSocialNetworks():Observable<SocialNetwork[]> {
    return this.http.get<SocialNetwork[]>(`${this.apiURL}cms/social-networks/`,this.getHttpOptions());
  }
  getDevelopments():Observable<Development[]> {
    return this.http.get<Development[]>(`${this.apiURL}developments/`,this.getHttpOptions());
  }
  getDevelopment(slug:string):Observable<Development> {
    return this.http.get<Development>(`${this.apiURL}developments/${slug}/`,this.getHttpOptions());
  }
  getUnits(slug:string,status:string='',bed_rooms:number=-1,family_room:boolean=undefined):Observable<Unit[]> {
    let path = `units/?ordering=price&tower__slug=${slug}`;
    if(status){
      path += `&status=${status}`;
    }
    if(bed_rooms != -1){
      path += `&type__bed_rooms=${bed_rooms}`;
    }
    if(family_room!==undefined){
      path+= '&type__family_room='+(family_room?'true':'false');
    }
    return this.http.get<Unit[]>(`${this.apiURL}${path}`,this.getHttpOptions());
  }
  getZones():Observable<Zone[]> {
    return this.http.get<Zone[]>(`${this.apiURL}zones/`,this.getHttpOptions());
  }
  getZone(slug:string):Observable<Zone> {
    return this.http.get<Zone>(`${this.apiURL}zones/${slug}/`,this.getHttpOptions());
  }
  createContact(contact:Contact):Observable<Contact> {
    return this.http.post<Contact>(`${this.apiURL}contact/`,contact,this.getHttpOptions());
  }
  getPromos():Observable<Promo[]> {
    return this.http.get<Promo[]>(`${this.apiURL}promos/`,this.getHttpOptions());
  }
  getPopUps():Observable<PopUp[]> {
    return this.http.get<PopUp[]>(`${this.apiURL}popups/`,this.getHttpOptions());
  }
  getFurnitures():Observable<Furniture[]> {
    return this.http.get<Furniture[]>(`${this.apiURL}furnitures/`,this.getHttpOptions());
  }
}
