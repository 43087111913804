<div class="availability section">
  <h2>{{'DEVELOPMENT.availability.title'|translate}}</h2>
  <div class="filters">
    <mat-form-field appearance="fill" *ngIf="developments">
      <mat-label>{{'MODELS.development'|translate}}</mat-label>
      <mat-select [(value)]="development" (selectionChange)="ngOnInit()">
        <ng-template ngFor let-d [ngForOf]="developments">
          <mat-option *ngIf="d.towers.length" [value]="d">
            {{d.name}}
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'MODELS.tower'|translate}}</mat-label>
        <mat-select [(value)]="tower">
          <mat-option *ngFor="let tower of development.towers" [value]="tower">
            {{tower.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="bed_rooms.length">
      <mat-label>{{"DEVELOPMENT.availability.bed_rooms"|translate}}</mat-label>
      <mat-select [(value)]="bed_room" (selectionChange)="bedroomChange()">
        <mat-option>{{'INTERFACE.all'|translate}}</mat-option>
        <ng-template ngFor let-br [ngForOf]="bed_rooms">
          <mat-option [value]="br">
            {{br}}
          </mat-option>
          <mat-option [value]="br+0.5">
            {{br}} + {{"DEVELOPMENT.availability.family_room"|translate}}
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="prices.length">
      <mat-label>{{"DEVELOPMENT.availability.price"|translate}}</mat-label>
      <mat-select [(value)]="price" (selectionChange)="applyFilters(false,false)">
        <mat-option>{{'INTERFACE.all'|translate}}</mat-option>
        <mat-option *ngFor="let p of prices" [value]="p">
          ${{p.toLocaleString('en',{maximumFractionDigits: 2,minimumFractionDigits: 2})}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="filters">
  </div>

  <div class="tower" *ngIf="tower">
    <div class="tower-image">
      <div class="svg" [inlineSVG]="tower.svg" (onSVGInserted)="selectTower()"></div>
    </div>
    <div id="unit-data"></div>
    <div class="unit" *ngIf="unit && unit_type">
      <h3>{{"DEVELOPMENT.availability.apartment"|translate}} {{unit.floor}}{{unit.number}}</h3>
      <div class="zone" *ngIf="zone" [ngClass]="zone.slug">
        <h4 *ngIf="blocked" class="blocked"><span>{{"DEVELOPMENT.availability.reserved"|translate}}</span></h4>
        <h4 *ngIf="promo" class="promo"><span>{{"DEVELOPMENT.availability.promo"|translate}}</span></h4>
        <h4><span>{{"DEVELOPMENT.availability.zone_title"|translate:zone}}</span></h4>
        <div class="benefits">
          <div class="benefit" *ngFor="let benefit of zone.benefits; index as i">
            <img [src]="benefit.image" [alt]="benefit.name">
            <span>{{benefit.name}}</span>
          </div>
        </div>
      </div>
      <a *ngIf="unit_type.virtual_tour_link" [href]="unit_type.virtual_tour_link" target="_blank" class="image">
        <div class="overlay">
          <img src="/assets/img/icons/360-1.svg" alt="">
          <span>{{"APPOINTMENT.virtual_tour"|translate}}</span>
        </div>
        <img [src]="unit_type.layout" alt="">
      </a>
      <div *ngIf="!unit_type.virtual_tour_link" class="image">
        <img [src]="unit_type.layout" alt="">
      </div>
      <div class="specs">
        <div>
          <div class="spec">{{"DEVELOPMENT.availability.bed_rooms"|translate}}: {{unit_type.bed_rooms}}</div>
          <div class="spec" *ngIf="unit_type.family_room">{{"DEVELOPMENT.availability.family_room"|translate}} <mat-icon>check</mat-icon></div>
          <div class="spec">{{"DEVELOPMENT.availability.rest_rooms"|translate}}: {{unit_type.rest_rooms}}</div>
          <div class="spec" *ngIf="unit_type.terrace">{{"DEVELOPMENT.availability.terrace"|translate}}: {{unit_type.terrace}}m<sup>2</sup></div>
          <div class="spec">{{"DEVELOPMENT.availability.area"|translate}}: {{unit_type.area}}m<sup>2</sup></div>
          <div class="spec">{{"DEVELOPMENT.availability.parking_spaces"|translate}}: {{unit_type.parking_spaces}}</div>
          <div class="spec">{{"DEVELOPMENT.availability.price"|translate}}: ${{unit.price.toLocaleString('en',{maximumFractionDigits: 2,minimumFractionDigits: 2})}}</div>
          <div class="spec">{{"DEVELOPMENT.availability.maintenance"|translate}}: ${{unit.maintenance_fee.toLocaleString('en',{maximumFractionDigits: 2,minimumFractionDigits: 2})}}</div>
          <div class="restrictions">
            *{{"DEVELOPMENT.availability.restrictions01"|translate}}
          </div>
          <div class="restrictions">
            **{{"DEVELOPMENT.availability.restrictions02"|translate}}
          </div>
          <div class="datasheet">
            <span>{{"DEVELOPMENT.availability.read_more"|translate}}</span>
            <a [href]="unit.datasheet" target="_blank" mat-flat-button color="primary" *ngIf="unit.datasheet">{{"DEVELOPMENT.our_apartments.datasheet"|translate}}</a>
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=qTrD-VyxJU6d6irzhbFELsS9-qctMKJAr7tUN3yn9c9UNU5aRUVGUzlPWDVEVFhBRFNBN0YzMFU4Vi4u&embed=true" target="_blank" mat-flat-button color="primary">{{"DEVELOPMENT.availability.find_your_home"|translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
