import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LanguageService } from "../../services/language.service";
import { Development } from "../../models/developments";
import { Content } from "../../models/cms";

@Component({
  selector: 'app-developments',
  templateUrl: './developments.component.html',
  styleUrls: ['./developments.component.scss']
})
export class DevelopmentsComponent implements OnInit {
  @Input() section: string = 'our-developments';
  developments: Development[] = [];
  contents: Content[];

  constructor(
    private api: ApiService,
    private language: LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_=>{
      this.api.getDevelopments().subscribe(data => {this.developments = data});
      this.api.getContents(this.section).subscribe(data => {this.contents = data});
    });
  }
}
