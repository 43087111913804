import { Component, OnInit, Input } from '@angular/core';
import { Development } from "../../models/developments";

@Component({
  selector: 'app-development-gallery',
  templateUrl: './development-gallery.component.html',
  styleUrls: ['./development-gallery.component.scss']
})
export class DevelopmentGalleryComponent implements OnInit {
  @Input() development: Development;
  active_image: string;
  active_title: string;

  constructor() { }

  ngOnInit(): void {
    this.active_image = this.development.images[0][0].slug;
    this.active_title = this.development.images[0][0].slug;
  }

  nextImage() {
    let n = false;
    let last_image = this.active_image;
    images:
    for(let images of this.development.images){
      image:
      for(let image of images){
        if(n){
          this.active_image = image.slug;
          n = false;
          this.active_title = images[0].slug;
          break images;
        }
        else if(this.active_image == image.slug){
          n = true;
        }
      }
    }
    if(n){
      this.active_image = this.development.images[0][0].slug;
      this.active_title = this.development.images[0][0].slug;
    }
  }

  prevImage() {
    let n = false;
    let last_image = '';
    let last_title = '';
    images:
    for(let images of this.development.images){
      image:
      for(let image of images){
        if(this.active_image == image.slug){
          break images;
        }
        last_image = image.slug;
        last_title = images[0].slug;
      }
    }
    if(last_image){
      this.active_image = last_image;
      this.active_title = last_title;
    }else{
      let images_ = this.development.images[this.development.images.length-1];
      this.active_image = images_[images_.length-1].slug;
      this.active_title = images_[0].slug;
    }
  }

  titleSelect(slug) {
    this.active_image = slug;
    this.active_title = slug;
  }
}
