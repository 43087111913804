<app-carousel section="sirenta-flex"></app-carousel>
<div id="main">
  <ng-template [ngIf]="content">
    <h1>{{content.title}}</h1>
    <markdown ngPreserveWhitespaces class="content">{{content.body}}</markdown>
    <div class="tour">
      <ng-template [ngIf]="content.url|is_matterport_embeddable">
        <iframe width="853" height="480" [src]="content.url|safe" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
      </ng-template>
      <ng-template [ngIf]="content.url && content.image">
        <a [href]="content.url" target="_blank" [ngClass]="(content.url|is_matterport_embeddable)?'sm':''">
          <img [src]="content.image" alt="">
        </a>
      </ng-template>
    </div>
  </ng-template>
  <h2 class="slogan">{{"FLEX.slogan"|translate}}</h2>
  <div class="plan">
    <div class="choose-term selection" *ngIf="selection.term.active">
      <h3>{{"FLEX.available_terms"|translate}}</h3>
      <p>{{"FLEX.choose_term"|translate}}</p>
      <div class="terms">
        <div class="term term-1">
          <h4>{{"FLEX.short_term"|translate}}</h4>
          <div (click)="setSelection('term',3)" class="set-selection"><span>3</span></div>
          <div (click)="setSelection('term',6)" class="set-selection"><span>6</span></div>
          <div (click)="setSelection('term',9)" class="set-selection"><span>9</span></div>
          <div class="months">
            {{"FLEX.months"|translate}}
          </div>
        </div>
        <div class="term term-2">
          <h4>{{"FLEX.long_term"|translate}}</h4>
          <div (click)="setSelection('term',12)" class="set-selection"><span>1</span></div>
          <div (click)="setSelection('term',24)" class="set-selection"><span>2</span></div>
          <div (click)="setSelection('term',36)" class="set-selection"><span>3</span></div>
          <div class="years">
            {{"FLEX.years"|translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="choose-layout selection" *ngIf="selection.layout.active || selection.zone.active">
      <h3>{{"FLEX.layout_types"|translate}}</h3>
      <p>{{"FLEX.choose_layout"|translate}}</p>
      <div class="layouts">
        <div class="layout set-selection" (click)="setSelection('layout',2)">
          2 {{"FLEX.rooms"|translate}}
        </div>
        <div class="layout set-selection" (click)="setSelection('layout',3)">
          3 {{"FLEX.rooms"|translate}}
        </div>
        <div class="layout family-room">
          <div class="checkbox selection set-selection" (click)="setSelection('family_room',!selection.family_room.value)">
            <img src="/assets/img/icons/checkbox-checked.png" alt="" *ngIf="selection.family_room.value">
            <img src="/assets/img/icons/checkbox-unchecked.png" alt="" *ngIf="!selection.family_room.value">
          </div>
          <div>
            {{"FLEX.family_room"|translate}}
          </div>
        </div>
      </div>
      <div class="zones" *ngIf="selection.zone.active && zones">
        <p>{{"FLEX.choose_zone"|translate}}</p>
        <div class="selection">
          <div [class]="'zone set-selection ' + zone.slug" *ngFor="let zone of zones" (click)="setSelection('zone',zone.name,zone.slug)" >
            {{"FLEX.zone"|translate}}
            <span>{{zone.name}}</span>
          </div>
        </div>
        <div class="zone-benefits">
          {{"FLEX.zone_benefits"|translate}}, <a routerLink="/promos" fragment="zones">{{"FLEX.click_here"|translate}}</a>.
        </div>
      </div>
    </div>
    <div class="choose-furniture selection" *ngIf="furnitures && selection.furniture.active">
      <h3>{{"FLEX.available_furniture"|translate}}</h3>
      <p>{{"FLEX.choose_furniture"|translate}}</p>
      <div class="furnitures">
        <div class="furniture set-selection" *ngFor="let furniture of furnitures" (click)="setSelection('furniture',furniture.name,furniture)">
          <div class="image">
            <img [src]="furniture.image" alt="">
          </div>
          <div class="name">
            {{furniture.name}}
          </div>
          <div class="description">
            {{furniture.description}}
          </div>
        </div>
      </div>
      <div class="includes" *ngIf="furniture_includes">
        <h4>{{"FLEX.packages_include"|translate}}</h4>
        <markdown ngPreserveWhitespaces>{{furniture_includes.body}}</markdown>
      </div>
      <div class="includes-disclaimer" *ngIf="furniture_includes_disclaimer">
        <markdown ngPreserveWhitespaces>{{furniture_includes_disclaimer.body}}</markdown>
      </div>
    </div>
    <div class="summary selection" *ngIf="selection.summary.active">
      <h3>{{"FLEX.summary.name"|translate}}</h3>
      <div class="bottom">
        <div class="result">
          <div class="line">
            <div class="label">
              {{"FLEX.summary.contract_term"|translate}}:
            </div>
            <div class="value">
              <ng-template [ngIf]="selection.term.value<12">
                {{selection.term.value}} {{"FLEX.months"|translate}}
              </ng-template>
              <ng-template [ngIf]="selection.term.value>=12">
                {{selection.term.value/12}} {{"FLEX.years"|translate}}
              </ng-template>
            </div>
          </div>
          <div class="line">
            <div class="label">
              {{"FLEX.summary.layout"|translate}}:
            </div>
            <div class="value">
              {{selection.layout.value}} {{"FLEX.rooms"|translate}}
              <ng-template [ngIf]="selection.family_room.value">+ {{"FLEX.summary.family_room"|translate}}</ng-template>
            </div>
          </div>
          <div class="line">
            <div class="label">
              {{"FLEX.summary.zone"|translate}}:
            </div>
            <div class="value">
              {{selection.zone.value}}
            </div>
          </div>
          <div class="line">
            <div class="label">
              {{"FLEX.summary.furniture"|translate}}:
            </div>
            <div class="value">
              {{selection.furniture.value}}
            </div>
          </div>
          <div class="line total">
            <div class="label">
              {{"FLEX.summary.monthly_rent"|translate}}:
            </div>
            <div class="value">
              $ {{selection.rent.value.toLocaleString('en',{maximumFractionDigits: 0})}} MXN*
            </div>
          </div>
          <div class="disclaimer">
            *{{"FLEX.summary.disclaimer"|translate}}
          </div>
        </div>
        <div class="contact">
          <p>{{"FLEX.summary.fill_form"|translate}}</p>
          <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=qTrD-VyxJU6d6irzhbFELsS9-qctMKJAr7tUN3yn9c9UNU5aRUVGUzlPWDVEVFhBRFNBN0YzMFU4Vi4u&embed=true" class="button">
            {{"FLEX.summary.sirenta_form"|translate}}
          </a>
        </div>
      </div>
    </div>
    <div class="back" *ngIf="!selection.term.active" (click)="selectionBack()">
      <div class="button">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </div>
    </div>
  </div>
</div>
