<div class="close" (click)="closeMenu()">
  <mat-icon>close</mat-icon>
</div>
<div class="contents">
  <div class="">
    <h4>{{"FLOATING_MENU.title"|translate}}</h4>
  </div>
  <div class="">
    <h4>{{"FLOATING_MENU.form.title"|translate}}</h4>
    <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=qTrD-VyxJU6d6irzhbFELsS9-qctMKJAr7tUN3yn9c9UNU5aRUVGUzlPWDVEVFhBRFNBN0YzMFU4Vi4u&embed=true">{{"FLOATING_MENU.form.name"|translate}}</a>
  </div>
  <div class="">
    <h4>{{"FLOATING_MENU.in_person_tour.title"|translate}}</h4>
    <a target="_blank" href="https://sirenta.com/recorridos/presencial/index.html">{{"FLOATING_MENU.in_person_tour.name"|translate}}</a>
  </div>
  <div class="">
    <h4>{{"FLOATING_MENU.virtual_tour.title"|translate}}</h4>
    <a target="_blank" href="https://sirenta.com/recorridos/virtual/index.html">{{"FLOATING_MENU.virtual_tour.name"|translate}}</a>
  </div>
  <div class="">
    <h4>{{"FLOATING_MENU.support.title"|translate}}</h4>
    <a target="_blank" href="http://sirenta.com/aplicaciones/index.html">{{"FLOATING_MENU.support.name"|translate}}</a>
  </div>
</div>
