import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewChild,ElementRef } from "@angular/core";
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';
import { Content } from "../../models/cms";
import { Zone } from "../../models/developments";
import { Promo } from "../../models/promos";

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromosComponent implements OnInit {
  content: Content;
  zones: Zone[];
  promo: Promo;
  @ViewChild('zone_data') zone_data: ElementRef;

  constructor(
    private api: ApiService,
    private language: LanguageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(value => {
      if(value == 'zones'){
        setTimeout(_ => {
          this.zone_data.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1000);
      }
    });
    this.language.language.subscribe(_ => {
      this.api.getContents('promos').subscribe(data => {
        if(data.length)this.content = data[0];
      });
      this.api.getZones().subscribe(data => {
        this.zones = data;
      });
      this.api.getPromos().subscribe(data => {
        if(data.length)
          this.promo = data[0];
      });
    });
  }
}
