import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

const languages = ['en','es'];
const default_language = 'en';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  languageSource = new BehaviorSubject<string>('default_language');
  language = this.languageSource.asObservable();

  constructor(
    private translate: TranslateService,
  ) { }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    if(languages.indexOf(language) >= 0)
      this.translate.setDefaultLang(language);
    else this.translate.setDefaultLang(default_language);
  }
  setLanguage(language:string) {
    if(languages.indexOf(language) >= 0){
      this.translate.use(language);
      this.languageSource.next(language);
    }
  }
  getLanguage() {
    if(this.translate.currentLang)
        return this.translate.currentLang;
    return this.translate.getBrowserLang();
  }
}
