import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LanguageService } from "../../services/language.service";
import { Content } from "../../models/cms";
import { UnitType, Furniture, Zone, FurniturePrice } from "../../models/developments";
import { ViewEncapsulation } from '@angular/core';


const FEATURED_DEVELOPMENT = 'Bosque-Real-Five';

@Component({
  selector: 'app-sirenta-flex',
  templateUrl: './sirenta-flex.component.html',
  styleUrls: ['./sirenta-flex.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SirentaFlexComponent implements OnInit {
  content: Content;
  furnitures: Furniture[];
  furniture: Furniture;
  zones: Zone[];
  zone: Zone;
  unit_types: UnitType[];
  unit_type: UnitType;
  furniture_includes: Content;
  furniture_includes_disclaimer: Content;
  layout: string = 'a';
  price: number;
  maintenance_fee: number;
  furniture_price: FurniturePrice;
  selection: any = {
    term: {
      active: true,
      value: undefined,
    },
    layout: {
      active: false,
      value: undefined,
    },
    family_room: {
      value: true,
    },
    zone: {
      active: false,
      value: undefined,
    },
    furniture: {
      active: false,
      value: undefined,
    },
    summary: {
      active: false,
    },
    rent: {
      value: 0,
    }
  }

  constructor(
    private api: ApiService,
    private language: LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_ => {
      this.api.getContents('sirenta-flex').subscribe(data => {
        if(data.length)this.content = data[0];
      });
      this.api.getZones().subscribe(data => {
        this.zones = data;
      });
      this.api.getDevelopment(FEATURED_DEVELOPMENT).subscribe(data => {
        this.unit_types = data.unit_types;
      });
      this.api.getFurnitures().subscribe(data => { this.furnitures = data });
      this.api.getContents('flex-furniture-includes').subscribe(data => {
        if(data.length)this.furniture_includes = data[0];
      });
      this.api.getContents('flex-furniture-includes-disc').subscribe(data => {
        if(data.length)this.furniture_includes_disclaimer = data[0];
      });
    });
  }

  getZone(slug:string){
    for(let zone of this.zones){
      if(zone.slug == slug){
        this.zone = zone;
        break;
      }
    }
  }

  getUnitType(bed_rooms:number,family_room:boolean){
    for(let unit_type of this.unit_types){
      if(unit_type.bed_rooms == bed_rooms && unit_type.family_room == family_room){
        this.unit_type = unit_type;
        break;
      }
    }
  }

  getPrice(){
    for(let price of this.unit_type.prices){
      if(price.zone == this.zone.slug){
        if(this.selection.term.value>12){
          this.price = price.price;
        }else{
          this.price = price.price_short_term;
        }
        this.maintenance_fee = price.maintenance_fee;
        break;
      }
    }
  }

  getFurniturePrice(){
    for(let furniture_price of this.unit_type.furniture_prices){
      if(this.furniture.slug == furniture_price.furniture){
        this.furniture_price = furniture_price;
        break;
      }
    }
  }

  setSelection(selection,value,index){
    this.selection[selection].value = value;
    this.selection[selection].index = index;
    this.selection[selection].active = false;
    if(selection == 'term'){
      this.selection['layout'].active = true;
    }
    if(selection == 'layout'){
      this.selection['zone'].active = true;
    }
    if(selection == 'zone'){
      this.getZone(index);
      this.getUnitType(this.selection['layout'].value,this.selection['family_room'].value);
      this.getPrice();
      this.selection['furniture'].active = true;
      if(this.selection['layout'].value == 2){
        this.layout = this.selection['family_room'].value?'b':'c';
      }else if(this.selection['layout'].value == 3){
        this.layout = this.selection['family_room'].value?'d':'a';
      }

    }
    if(selection == 'furniture'){
      this.furniture = index;
      this.getFurniturePrice();
      this.selection['rent'].value = this.price + this.maintenance_fee + this.furniture_price.price;
      this.selection['summary'].active = true;
    }
  }
  selectionBack(){
    if(this.selection['layout'].active){
      this.selection['layout'].active = false;
      this.selection['term'].active = true;
    }
    if(this.selection['zone'].active){
      this.selection['zone'].active = false;
      this.selection['term'].active = true;
    }
    if(this.selection['furniture'].active){
      this.selection['furniture'].active = false;
      this.selection['zone'].active = true;
    }
    if(this.selection['summary'].active){
      this.selection['summary'].active = false;
      this.selection['furniture'].active = true;
    }
  }
}
