<nav>
  <ul>
    <li><a routerLink="/">{{'NAVBAR.home'|translate}}</a></li>
    <li><a href="https://forms.office.com/Pages/ResponsePage.aspx?id=qTrD-VyxJU6d6irzhbFELsS9-qctMKJAr7tUN3yn9c9UNU5aRUVGUzlPWDVEVFhBRFNBN0YzMFU4Vi4u&embed=true" target="_blank" fragment="HOGAR">{{'NAVBAR.find_your_home'|translate}}</a>
    <li><a routerLink="/" fragment="availability">{{'NAVBAR.availability'|translate}}</a>
      <ul>
        <ng-template ngFor let-development [ngForOf]="developments">
          <li *ngIf="development.towers.length"><a [routerLink]="['/development/',development.slug]">{{development.name}}</a></li>
        </ng-template>
      </ul>
    </li>
    <li routerLinkActive="active"><a routerLink="promos">{{'NAVBAR.promos'|translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="tours">{{'NAVBAR.tours'|translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="sirenta-flex">{{'NAVBAR.flex'|translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="company">{{'NAVBAR.company'|translate}}</a></li>
    <li routerLinkActive="active"><a routerLink="contact">{{'NAVBAR.contact'|translate}}</a></li>
  </ul>
  <ul class="floating-nav">
    <li class="lang es"><span (click)="setLanguage('es')">Español</span></li>
    <li class="lang en"><span (click)="setLanguage('en')">English</span></li>
    <li><a href="https://residentes.sirenta.com/" target="_blank">{{'NAVBAR.residents'|translate}}</a></li>
    <li><a href="https://misirenta.com/" target="_blank">{{'NAVBAR.my_sirenta'|translate}}</a></li>
  </ul>
</nav>
