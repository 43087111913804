<div class="logo">
  <a routerLink="/" routerLinkActive="active">
    <img src="/assets/img/logo.svg" alt="SìRenta">
  </a>
</div>
<mat-toolbar [ngClass]="fixed_header?'active':''">
  <button mat-icon-button class="menu-button">
    <mat-icon (click)="toggleMainDrawer.emit()">menu</mat-icon>
  </button>
  <a routerLink="/" routerLinkActive="active" class="logo">
    <img src="/assets/img/logo.svg" alt="SìRenta">
  </a>
</mat-toolbar>

<app-nav class="main-nav"></app-nav>
