<div class="developments section">
  <h2>{{'DEVELOPMENTS.title'|translate}}</h2>
  <div class="contents">
    <div class="content" *ngFor="let content of contents">
      {{content.body}}
    </div>
  </div>
  <div class="development" *ngFor="let development of developments" [routerLink]="'/development/'+development.slug" routerLinkActive="active" [ngClass]="development.towers.length?'':'inactive'">
    <div class="image-wrapper">
      <div class="image">
        <img [src]="development.image" [alt]="development.name">
      </div>
      <div class="coming_soon" *ngIf="!development.towers.length && section == 'tours'">
        <span>{{"DEVELOPMENTS.coming_soon"|translate}}</span>
      </div>
    </div>
    <div class="logo">
      <div class="logo-wrapper">
        <img [src]="development.logo" [alt]="development.name">
      </div>
    </div>
  </div>
</div>
