<div class="section">
  <h2>{{"DEVELOPMENT.gallery.title"|translate}}</h2>
  <div class="galleries">
    <div class="titles">
      <div
        class="title"
        [ngClass]="active_title==images[0].slug?'active':''"
        *ngFor="let images of development.images"
        (click)="titleSelect(images[0].slug)">
        {{images[0].description}}
      </div>
    </div>
    <div class="gallery">
      <ng-template ngFor let-images [ngForOf]="development.images">
        <div
          class="image"
          [ngClass]="[
            active_image==image.slug?'active':''
          ]"
          *ngFor="let image of images">
          <img [src]="image.image" [alt]="image.description">
        </div>
      </ng-template>
      <div class="prev" (click)="prevImage()">
        <span><mat-icon>chevron_left</mat-icon></span>
      </div>
      <div class="next" (click)="nextImage()">
        <span><mat-icon>chevron_right</mat-icon></span>
      </div>
    </div>
  </div>
</div>
