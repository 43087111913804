<div class="bosque-real section">
  <h2>{{'BOSQUE_REAL.title'|translate}}</h2>
  <div class="contents" *ngIf="content">
    <div class="content" *ngIf="content">
      <markdown ngPreserveWhitespaces>{{content.body}}</markdown>
    </div>
  </div>
  <div class="maps">
    <div class="points-of-interest-button" *ngIf="points_of_interest">
      <a href="{{points_of_interest.file}}" target="_blank" mat-raised-button color="primary">{{"BOSQUE_REAL.points_of_interest"|translate}}</a>
    </div>
    <div class="wrapper">
      <div class="google-maps">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
          <agm-marker
                  [latitude]="marker.lat"
                  [longitude]="marker.lng"
                  [opacity]="marker.alpha"
                ></agm-marker>
        </agm-map>
      </div>
      <div class="points-of-interest">
        <img [src]="content.image" [alt]="'BOSQUE_REAL.points_of_interest'|translate" *ngIf="content">
      </div>
    </div>
  </div>
</div>
