<app-carousel section="contact"></app-carousel>
<h1>{{'NAVBAR.contact'|translate}}</h1>
<div id="main">
  <div class="contact">
    <div class="contact_types" *ngIf="!posted">
      <div class="contact-type" [ngClass]="contact_type == 'individual'?'active':''" (click)="selectContactType('individual')">
        <div class="icon">
          <img [src]="contact_types.individual.icon" alt="">
        </div>
        <div class="info">
          <div class="name">
            {{contact_types.individual.name|translate}}
          </div>
          <div class="slogan">
            {{contact_types.individual.slogan|translate}}
          </div>
        </div>
      </div>
      <div class="contact-type" [ngClass]="contact_type == 'company'?'active':''" (click)="selectContactType('company')">
        <div class="icon">
          <img [src]="contact_types.company.icon" alt="">
        </div>
        <div class="info">
          <div class="name">
            {{contact_types.company.name|translate}}
          </div>
          <div class="slogan">
            {{contact_types.company.slogan|translate}}
          </div>
        </div>
      </div>
      <div class="contact-type" [ngClass]="contact_type == 'broker'?'active':''" (click)="selectContactType('broker')">
        <div class="icon">
          <img [src]="contact_types.broker.icon" alt="">
        </div>
        <div class="info">
          <div class="name">
            {{contact_types.broker.name|translate}}
          </div>
          <div class="slogan">
            {{contact_types.broker.slogan|translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="form-container" [ngClass]="posted?'posted':''">
      <div class="form-icon">
        <img [src]="contact_types.individual.icon" *ngIf="contact_type=='individual'">
        <img [src]="contact_types.company.icon" *ngIf="contact_type=='company'">
        <img [src]="contact_types.broker.icon" *ngIf="contact_type=='broker'">
      </div>
      <form action="." method="post" *ngIf="!posted">
        <div class="form-header">
          <h3 *ngIf="contact_type=='individual'">{{contact_types.individual.name|translate}}</h3>
          <h3 *ngIf="contact_type=='company'">{{contact_types.company.name|translate}}</h3>
          <h3 *ngIf="contact_type=='broker'">{{contact_types.broker.name|translate}}</h3>
          <p *ngIf="contact_type=='individual'">{{contact_types.individual.summary|translate}}</p>
          <p *ngIf="contact_type=='company'">{{contact_types.company.summary|translate}}</p>
          <p *ngIf="contact_type=='broker'">{{contact_types.broker.summary|translate}}</p>
        </div>
        <div class="form-field">
          <mat-form-field appearance="standard">
            <mat-label>{{"CONTACT.form.name"|translate}}</mat-label>
            <input matInput placeholder="" [formControl]="name" required [readonly]="posting">
            <mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field appearance="standard">
            <mat-label>{{"CONTACT.form.last_name"|translate}}</mat-label>
            <input matInput placeholder="" [formControl]="last_name" required [readonly]="posting">
            <mat-error *ngIf="last_name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field" *ngIf="contact_type=='company'||contact_type=='broker'">
          <mat-form-field appearance="standard" *ngIf="contact_type=='company'||contact_type=='broker'">
            <mat-label *ngIf="contact_type=='company'">{{"CONTACT.form.company_name"|translate}}</mat-label>
            <mat-label *ngIf="contact_type=='broker'">{{"CONTACT.form.real_state_agency"|translate}}</mat-label>
            <input matInput placeholder="" [formControl]="company_name" required [readonly]="posting">
            <mat-error *ngIf="company_name.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field appearance="standard">
            <mat-label>{{"CONTACT.form.email"|translate}}</mat-label>
            <input matInput [formControl]="email" required [readonly]="posting">
            <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field" *ngIf="contact_type=='company'">
          <mat-form-field appearance="standard" *ngIf="contact_type=='company'">
            <mat-label>{{"CONTACT.form.choose_option"|translate}}</mat-label>
            <mat-select required [readonly]="posting" [formControl]="company_position">
              <mat-option value=""></mat-option>
              <mat-option value="owner">{{"CONTACT.form.option_owner"|translate}}</mat-option>
              <mat-option value="employee">{{"CONTACT.form.option_employee"|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field appearance="standard">
            <mat-label>{{"CONTACT.form.phone"|translate}}</mat-label>
            <input matInput placeholder="" [formControl]="phone" required [readonly]="posting">
            <mat-error *ngIf="phone.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-button">
            <button mat-raised-button color="basic" (click)="sendForm()">{{"CONTACT.form.send"|translate}}</button>
        </div>
      </form>
      <div class="thanks" *ngIf="posted">
        <span>{{"CONTACT.form.thanks"|translate}}</span>
      </div>
    </div>
  </div>
</div>
