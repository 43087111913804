import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LanguageService } from "../../services/language.service";
import { Content } from "../../models/cms";

@Component({
  selector: 'app-bosquereal',
  templateUrl: './bosquereal.component.html',
  styleUrls: ['./bosquereal.component.scss']
})
export class BosquerealComponent implements OnInit {
  content: Content;
  points_of_interest: Content;
  latitude: number = 19.4318513;
  longitude: number = -99.2836605;
  zoom: number = 16;
  marker = {
    lat: 19.4318513,
    lng: -99.2836605,
    alpha: 1,
  };

  constructor(
    private api: ApiService,
    private language: LanguageService,
  ) { }

  ngOnInit(): void {
    this.language.language.subscribe(_=>{
      this.content = undefined;
      this.points_of_interest = undefined;
      this.api.getContents('bosque-real').subscribe(data => {
        if(data.length) this.content = data[0];
      });
      this.api.getContents('points-of-interest').subscribe(data => {
        if(data.length) this.points_of_interest = data[0];
      });
    });
  }
}
